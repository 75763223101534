

window.startAnalytics = function() {
    try {
        // const analyticsId = "G-9XCQG84EYC"; // ID found in gx-chase
        const analyticsId = "G-RYD0GNYQKE"; // ID found in this repo

        // Analytics code goes HERE
        const scriptTag = document.createElement("script");
        window.document.head.appendChild(scriptTag);
        scriptTag.onload = function() {
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', analyticsId);
        };
        scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
    } catch (e) {
        console.log("Error", e);
    }
}

window.showAnalyticsBanner = function() {
    try {
        const cookieBanner = document.getElementById("analytics-consent-banner");
        cookieBanner.style.display = "block";
    } catch (e) {
        console.log("Error", e);
    }
}

window.hideAnalyticsBanner = function() {
    try {
        const cookieBanner = document.getElementById("analytics-consent-banner");
        cookieBanner.style.display = "none";
    } catch (e) {
        console.log("Error", e);
    }
}

window.saveAcceptAnaltics = function() {
    window.localStorage.setItem("analytics_accepted", "true");
}

window.saveRejectAnaltics = function() {
    window.localStorage.setItem("analytics_accepted", "false");
}

window.shouldShowCookieBanner = function() {
    const accepted = window.localStorage.getItem("analytics_accepted");
    return accepted !== "true" && accepted !== "false";
}

window.areAnalyticsAccepted = function() {
    const accepted = window.localStorage.getItem("analytics_accepted");
    return accepted === "true";
}

window.acceptAnalyticsCookies = function() {
    window.hideAnalyticsBanner();
    window.saveAcceptAnaltics();
    window.startAnalytics();
}

window.rejectAnalyticsCookies = function() {
    window.hideAnalyticsBanner();
    window.saveRejectAnaltics();
}

window.init = function() {
    if (window.areAnalyticsAccepted()) {
        window.startAnalytics();
    } else if (window.shouldShowCookieBanner()) {
        window.showAnalyticsBanner();
    }
}


window.revokeCookieConsent = function(event) {
    event.preventDefault();
    window.localStorage.removeItem("analytics_accepted");
    window.location.reload();
}

// window.init();
