<template>
  <div class="menu">
    <ul>
      <li  v-for="menu in menus" :key="menu.id">
        <h6><a :href="menu.link">{{ menu.name }}</a></h6>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'gxMenu',
  data () {
    return {
      menus: [
        
        {
          id: 1,
          link: "/team",
          name: "Team",
        },
        {
          id: 2,
          link: "/careers",
          name: "Careers"
        },
        {
          id: 3,
          link: "/contact-us",
          name: "Contact us",
        }
      ]
    }
    
  }
}
</script>