<template>
  <header id="mainHeader">
    <div class="header-container">
      
      <div class="hamburguer" @click="isShowMenu()">
        <span></span>
        <span></span>
      </div>  
      <div class="topnav" id="burgerMenu">
        <a href="/news">News</a>
        <a href="/perspectives">Perspectives</a>        
        <a href="/about-galytix">About Us</a>
        <!-- <a href="/data">DataFactory</a> -->
        <a href="/team">Team</a>
        <a href="/careers">Careers</a>
        <a href="/contact-us">Contact Us</a>
      </div> 
      <div class="logoIcon gx-hidden-lg">
        <a href="/">
          <img src="../../assets/imgs/gx-icon.png" />
        </a>
      </div>
        
      <gxCommerceBar class="gx-show-lg" />
      <div class="logo gx-show-lg">
        <a href="/">
          <img src="../../assets/imgs/gx-logo-full.svg" />
        </a>
      </div>
      <gxMenu class="gx-show-lg" />
    </div>
  </header>
</template>

<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import gxCommerceBar from "./menu/commerceBar.vue";
import gxMenu from "./menu/menu.vue";

export default {
  components: {
    gxCommerceBar,
    gxMenu,
  },
  name: "gxHeader",

  methods: {
    isShowMenu () {
      const hamBurguer = document.getElementById("burgerMenu");
      if (hamBurguer.className === "topnav") 
      {hamBurguer.className += " show";}
      else {
        hamBurguer.className = "topnav";
      }
    },

    animation() {
      gsap.registerPlugin(ScrollTrigger);      

      ScrollTrigger.clearMatchMedia();
      ScrollTrigger.refresh();

      const showAnim = gsap.from('#mainHeader', { 
        yPercent: -100,
        paused: true,
        duration: 0.2
      }).progress(1);

      ScrollTrigger.create({
        start: "top top",
        end: 99999,
        onUpdate: (self) => {
          self.direction === -1 ? showAnim.play() : showAnim.reverse()
        }
      });
    },
  },
  mounted() {
    this.animation();
  },
};
</script>

<style lang="scss">
header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    transition: ease 0.4s;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;   
    z-index: 99; 
    background: #000000;
    // background: rgba(0, 0, 0, 0.5);
  // @include breakpoint($large) {
  //   height: 60px;
  // }
  // @include iPad() {
  //   height: 60px;
  // }
  
  .header-container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    padding: 0 12px;
    @include breakpoint($x-large) {
      flex-direction: row;
    }
    // @include iPad() {
    //   flex-direction: row-reverse;
    // }
  }
}
.hamburguer {
  position: absolute;
  left: 20px;
  z-index: 999;
  @include breakpoint($x-large) {
    display: none;
  }
  cursor: pointer;
  span {
    border-bottom: 1px solid white;
    width: 15px;
    padding-bottom: 5px;
    display: block;
  }
}
.menu {
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    li {
      display: block;
      padding: 0 15px;
      a {
        text-decoration: none;
        color: $white;
        text-transform: capitalize;
      }
    }
  }
}
.logo {
  max-width: 270px;
  margin-right: 70px;
  height: 14px;
  @include breakpoint($x-large) {
    height: auto;
    margin-right: 0;
  }
  @include iPad() {
    height: 14px;
    margin-right: 70px;
  }
  img {
    height: 14px;
    width: auto;
    @include breakpoint($large) {
      height: auto;
      width: 100%;
    }
  }
}
.search {
  cursor: pointer;
  span {
    background: url("../../assets/imgs/icons/search.svg") no-repeat center
      center transparent;
    display: block;
    width: 24px;
    height: 24px;
  }
}


/* -- mob css -- */

.topnav {
  display: block;
  background-color: #000000;
  position: absolute;
  left: 0;
  top: -3vh;
  width: 100%;
  padding: 0px 40px;  
  margin-top: 60px;
  border-top:1px solid #3a3a3a;
  visibility: hidden;
  transition:all 0.2s linear;
  opacity: 0;
  @include breakpoint($x-large) {
    display: none;
  }
}

.topnav a {
  color: #f5f5f7;
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: left;
  height: 44px; 
  border-bottom: 1px solid #3a3a3a;
  text-transform: capitalize;

}
.gx-show-lg {
  display: block;
}
.gx-hidden-lg {    
  display: none;
}
@media screen and (max-width: 1280px) {

  .gx-show-lg {
    display: none;
  }
  .gx-hidden-lg {
    display: block;
  }
 
  .topnav.show { 
    opacity: 1;
    display: block;
    position: absolute;
    top: 0;
    height: 100vh;
    visibility: visible;
  }
  .logoIcon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .logoIcon img {
    width: 22px;
  }
}
</style>