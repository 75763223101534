<template>
  <footer>
    <div class="footer-container"> 
      <div class="flex-container">
        <div class="nav-container">
          <!-- <nav>
            <h6>Product</h6>
            <ul>
              <li><a href="#">GX Platform</a></li>
            </ul>
          </nav> -->
          <nav>
            <h6>Updates</h6>
            <ul>
              <li><a href="/perspectives">Perspectives</a></li>
              <li><a href="/news">News</a></li>
            </ul>
          </nav>
           <nav>
            <h6>About Galytix</h6>
            <ul>
              <li><a href="/about-galytix">About Us</a></li>
              <!-- <li><a href="/data">DataFactory</a></li> -->
              <li><a href="/team">Team</a></li>
              <li><a href="/careers">Careers</a></li>
              <li><a href="/jobs">Browse Jobs</a></li>
              <li><a href="/contact-us">Contact Us</a></li>
            </ul>
          </nav>
           <nav>
            <ul>
              <li><a target="_blank" href="/gx/Galytix%20Privacy%20policy.pdf">Privacy Policy</a></li>
              <li><a target="_blank" href="/gx/Galytix%20Cookie%20policy.pdf">Cookie Policy</a></li>
              <li><a href="/#" @click="revokeCookieConsent">Revoke Cookie Consent</a></li>
              <li>
                <div class="social-media-heading">Follow us on</div>
                <div class="social-media-icon-wrap">
                  <a class="btn-social" href="https://www.linkedin.com/company/galytix/mycompany/?viewAsMember=true" target="_blank" title="#">
                    <!-- <svg class="svg-inline--fa fa-linkedin fa-w-14" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path></svg> -->
                    <svg class="svg-inline--fa fa-linkedin fa-w-14" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.16 60.01" data-fa-i2svg=""><path fill="currentColor" d="m33.22,25.34c.83-.94,1.52-1.89,2.38-2.69,2.63-2.48,5.75-3.73,9.38-3.7,2,.02,3.97.16,5.9.72,4.41,1.27,6.97,4.31,8.19,8.61.92,3.23,1.08,6.54,1.09,9.87,0,7.01-.02,14.02,0,21.04,0,.66-.18.83-.83.82-3.61-.03-7.23-.03-10.84,0-.64,0-.77-.19-.77-.79.02-6.67.03-13.34,0-20.02,0-1.67-.11-3.34-.58-4.97-.86-2.99-3.01-4.52-6.15-4.35-4.28.23-6.51,2.35-7.06,6.7-.13,1.04-.19,2.08-.19,3.13,0,6.49,0,12.98.02,19.47,0,.65-.16.84-.82.83-3.64-.03-7.28-.03-10.92,0-.58,0-.75-.15-.75-.74.02-12.85.02-25.7,0-38.54,0-.64.21-.78.81-.78,3.46.03,6.91.03,10.37,0,.64,0,.8.2.79.8-.04,1.53,0,3.07,0,4.61v-.02Zm-19.78-4.54c0-.6-.09-.88-.8-.87-3.66.04-7.33.03-10.99,0-.57,0-.69.19-.69.72,0,12.87,0,25.74,0,38.61,0,.6.17.74.75.74,3.61-.03,7.22-.03,10.83,0,.73,0,.91-.21.9-.91-.03-6.36-.02-12.71-.02-19.07s0-12.82.01-19.22h.01ZM7.23,0C3.25,0,0,3.27,0,7.24c0,3.93,3.28,7.18,7.21,7.18s7.22-3.25,7.22-7.24c-.01-3.94-3.26-7.18-7.2-7.18Z" /></svg>
                  </a>
                  <a class="btn-social twitter" href="https://mobile.twitter.com/galytix#" target="_blank" title="#">
                    <svg class="svg-inline--fa fa-w-16 bi bi-twitter-x" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  viewBox="0 0 16 16"><path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"/></svg>
                  </a>
                </div>
              </li>
            </ul>
          </nav>
          
        </div>
        <div class="footer-logo">
          <div class="footer-logo-wrap">
            <a href="/"><img src="../../assets/imgs/gx-logo-full.svg" /></a>
          </div>
          <div class="address">
            GALYTIX LTD<br>
            3RD FLOOR, 86-90 PAUL STREET,<br>
            LONDON EC2A 4NE, UK 
          </div>
          <div class="iso-logo">
            <img src="../../assets/imgs/iso-logo.svg" />
          </div>          
        </div>        
      </div>
      <!-- <div class="hr"></div>
      <div class="copy">
        <p class="text-color-2">© {{(new Date()).getFullYear()}} Galytix. All rights reserved.</p>
      </div> -->
    </div>
    <div class="copy">
      <div class="container">
        <p class="text-color-2">© {{(new Date()).getFullYear()}} Galytix. All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>
//import { db } from '../../../firebase'
import './scripts'


//const documentPath = 'contacts'

export default {
  name: "gxFooter",
  data() {
    return {
      firebaseData: null,
      formData: {
        email: ''
      },
      state: 'loading',
      errorMessage: ''
    }
  },
  firestore() {
    return {
      //firebaseData: db.doc(documentPath)
      firebaseData: {}
    }
  },
  methods: {
    updateFirebase: function () {     
    },

    revokeCookieConsent: function(event) {
      window.revokeCookieConsent(event);
    }
  },
  mounted: function() {
    window.init();
  }
};
</script>

<style lang="scss">
.hr {
  border-top: 1px solid #424245;
}
.svg-inline--fa.fa-w-14 {
    width: 0.875em;
}
.svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}
.social-media-icon-wrap svg path {
    fill: #006cce;
}
.social-media-icon-wrap .twitter svg path {
  fill: #ffffff;
}
footer {  
  padding-top: 1.5rem;
  background: #181c1f;
  color: #8b939b;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 500;
    @media screen and (max-width: ($mobile-breakpoint)) {      
      background: transparent;
    }
  .flex-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
   
    @include breakpoint($medium) {
      flex-direction: row;
      padding:0 0px;      
    }
    @media screen and (max-width:($xx-large)) {      
      padding:0 22px;
    }
    @media screen and (max-width: ($mobile-breakpoint)) {
      padding-bottom: 10px;
      padding-left: 0;
      padding-right: 0;
      flex-direction: column;      
    }
    @media screen and (orientation:landscape) and (max-height: ($mobile-landscape)) {
      flex-direction: row;
    }
  }
  .footer-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 0px;
    
    @media screen and (max-width: ($mobile-breakpoint)) {
      padding: 0 7px;
    }  
  }
  .footer-logo {
    padding-top: 0;
    color: #8b939b;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 500;
    padding-left: 15px;
    .address {
      line-height: 1.4rem;
    }
    .footer-logo-wrap {
      display: flex;
      margin-bottom: 1rem;
      img {
        width: 200px;
      }
    } 
  }  
  li a {   
    color: #8b939b;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 500;
    text-transform: capitalize;
    display: block;
    padding: 5px 0;
    @media screen and (max-width:($xx-large)) {
      font-size: 1.1rem;
    }
    @media screen and (max-width: ($mobile-breakpoint)) {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }
  .nav-container {
    display: flex;    
    flex: 1;
    margin-bottom: 1.5rem;
    @media screen and (max-width: ($mobile-breakpoint)) {
      flex-direction: column;
      margin-bottom: 2.5rem;
    }  
    nav + nav {
      padding-left: 15px;
    }
    nav {
      border-right: 1px dashed #3a3a3a;     
      padding-right: 15px;
      flex: 1;

      @media screen and (max-width: ($mobile-breakpoint)) {
        border-right: none;
        padding-left: 15px;
      }
      ul {        
        margin-bottom: 1rem;
        @media screen and (max-width: ($mobile-breakpoint)) {
          margin-bottom: 0;
        }        
        li {
          @media screen and (max-width: ($mobile-breakpoint)) {
            border-bottom: 1px solid #3a3a3a;
            padding: 10px 0;
          }          
        }
      }
    }
    
    h6 {
      padding-bottom: 5px;
      font-weight: 500;
      color: #ffffff;
      font-size: 1.2rem;
      text-transform: capitalize;
      margin-bottom: 0;
      @media screen and (max-width: ($mobile-breakpoint)) {
        border-bottom: 1px solid #3a3a3a;
        padding-bottom:10px ;
        display: none;
      }      
    }   
  }
  .copy {
    background-color: #0e1112;
    @media screen and (max-width: ($mobile-breakpoint)) {
      padding: 9px 19px;
      background-color: transparent;
    } 
    .container {
      max-width: 1440px;      
      margin: 0 auto;  
      @media screen and (orientation:landscape) and (max-height: ($mobile-landscape))  {        
        padding:0 0px;
      }        
      @media screen and (max-width:($xx-large)) {      
        padding:0 22px;
      }
      @media screen and (max-width: ($mobile-breakpoint)) {
        padding:0;
      }
    }
    p {
      font-size: 1.2rem;
      line-height: 2.2rem;
      color: #666666;
      font-weight: 500;
      @media screen and (max-width:($xx-large)) {
        font-size: 1.1rem;
      }
      @media screen and (max-width: ($mobile-breakpoint)) {
        font-size: 1.2rem;
      }
    }    
  }
}
.iso-logo {
  margin-top: 15px;
}
.iso-logo > img {
  max-width: 118px;
}
.social-media-heading {
  font-family:$secondary-font ;
      font-size: 1.2rem;
      line-height: 2.2rem;
      font-weight: 500;
      margin-top: 10px;
      color: #fff;
      @media screen and (max-width:($xx-large)) {
        font-size: 1.2rem;
        line-height: 2.2rem;
      }
      @media screen and (max-width: ($mobile-breakpoint)) {
        font-size: 1.2rem;
        line-height: 2.2rem;
      }
    }
    .social-media-icon-wrap {
      display: flex;
      margin-top: 5px;
      .btn-social {
        color: #ffffff;
        font-size: 20px;
        margin-right: 5px;
        padding: 0;
      // }
      // .btn-social {
        background: black;
        display: flex;
        height: 26px;
        width: 25px;
        align-items: center;
        justify-content: center;
        border: 1px solid #353a3c;
        border-radius: 2px;
        transform: translateY(1px);
        padding-top: 1px;
        padding-left: 1px;
      }
    }

/* Cookie banner */
#analytics-consent-banner {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #181c1f;
  color: #8b939b;
  padding: 20px;
  font-size: 14px;
  display: block;
  text-align: center;
  border-top:1px solid #0e1112;
  line-height: 18px;
  z-index: 10;
}
.cookie-policy-banner-link {
  color: #8b939b;
  font-size: 14px;
}
.analytics-button {
  background: transparent;
  padding: 5px 15px;
  color: #fff;
  text-decoration: none;
  font-size: 11px;
  font-family: Arial,sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all .3s;
  border: 2px solid #006cce;
  line-height: 1;
  border-radius: 20px;
  cursor: pointer;
}
.analytics-reject {
  background-color: transparent;
}
.analytics-accept {
  color: #fff;
  background-color: #006cce;
  border-color: #006cce
}
.show-on-mobile {
  display: none;
}
.mob-margin-top-md {
  margin-top: 0;
}
@media screen and (max-width: 1024px) { 
  .show-on-mobile { 
    display: block; 
  } 
  .mob-margin-top-md {
    margin-top: 10px;
  }
}
</style>